const causesAutofill = () => {
    return [
        {
            title: "Black Lives Matter",
            image: "https://images-na.ssl-images-amazon.com/images/I/71FZk1ZBf2L._AC_SL1500_.jpg",
            description: "Black Lives Matter (BLM) is a decentralized political and social movement protesting against incidents of police brutality and all racially motivated violence against black people." +
                "\n" +
                "We appreciate your support of the movement and our ongoing fight to end state-sanctioned violence, liberate Black people, and end white supremacy forever." +
                "\n" +
                "You can make a difference by protesting, educating, listening, consciously shopping, and donating",
            donations: {
                website: true,
                direct: true,
                petition: true,
            },
            donationData: {
                website: "https://blacklivesmatter.com/",
                direct: "https://secure.actblue.com/donate/ms_blm_homepage_2019",
                petition: "https://www.naacp.org/campaigns/we-are-done-dying/",
            }

        },
        {
            title: "Stop AAPI Hate",
            image: "https://secureservercdn.net/104.238.69.231/a1w.90d.myftpupload.com/wp-content/themes/stopaapihate/assets/image/logos/stopaapihate-logo.png",
            description: "Our communities stand united against racism. Hate against Asian American Pacific Islander communities has risen during the COVID-19 pandemic. Together, we can stop it." +
                "\n" +
                "1\n" +
                "Take Action: Approach the targeted person, introduce yourself, and offer support.\n" +
                "2\n" +
                "Actively Listen: Ask before taking any actions and respect the targeted person’’s wishes. Monitor the situation if needed.\n" +
                "3\n" +
                "Ignore Attacker: Using your discretion, attempt to calm the situation by using your voice, body language, or distractions.\n" +
                "4\n" +
                "Accompany: If the situation escalates, invite the targeted person to join you in leaving.\n" +
                "5\n" +
                "Offer Emotional Support: Help the targeted person by asking how they’re feeling. Assist them in figuring out what they want to do next.",
            donations: {
                website: true,
                direct: true,
                petition: true,
            },
            donationData: {
                website: "https://stopaapihate.org/",
                direct: "https://donate.givedirect.org/?cid=14711",
                petition: "https://www.change.org/t/asian-americans-en-us",
            }
        },
        {
            title: "Support Punjabi Farmers",
            image: "http://res.cloudinary.com/activst/image/upload/v1617222086/ahjfzt1cc3d2tojm1v7g.png",
            description: "Over 75% of Punjab’s 30 million-strong population is involved in agriculture. The three new laws fail to protect farmers from exploitation and to ensure fair prices for their produce. These laws could lead to large private corporate entities taking advantage of farmers.The funds you donate will be used towards giving protesting farmers the assistance they need to sustain their fight:\n-Providing ambulances and arranging for doctors to go along the farmers to Delhi\n-Portable toilets that will be towed behind vehicles for use by families\n-Financial assistance for diesel for farmers' tractors\n-Rent for buses and trucks (some of the farmers do not have their own vehicles) \n-Mattresses and bedding for overnight use\n",
            donations: {
                website: true,
                direct: true,
                petition: true,
                gofundme: true,
            },
            donationData: {
                website: "https://www.khalsaaid.org/news/farmers-protest-2020",
                direct: "https://www.khalsaaid.org/donate",
                petition: "https://www.change.org/p/justice-for-farmers-of-india",
                gofundme: "https://www.gofundme.com/f/help-farmers-and-their-families-in-panjab?qid=167bdacde14dc02f1813e3281d5c8a93"
            }
        },
        {
            title: "No Kid Hungry",
            image: "http://res.cloudinary.com/activst/image/upload/v1617222259/c1upgn32tdum0jtkrtws.png",
            description: "America's kids need us. Because of the coronavirus, millions of vulnerable children are losing the school meals they depend on. For some, it's the only food they'll receive in a given day. We have a plan to feed kids, but we need your help today to continue our work to ensure every kid gets three meals a day.\n",
            donations: {
                website: true,
                direct: true,
            },
            donationData: {
                website: "https://www.nokidhungry.org/who-we-are",
                direct: "https://secure.nokidhungry.org/site/Donation2?df_id=18770&mfc_pref=T&18770.donation=form1&s_src=googlemain&s_subsrc=210HAGN1G&utm_source=google&utm_medium=search&utm_campaign=2021_brand&utm_content=paid",
            },
        },
        {
            title: "Coronavirus Emergency Response",
            image: "http://res.cloudinary.com/activst/image/upload/v1617226899/zpcqzkdyybefpjuvkisg.png",
            description: "Join World Vision in responding to the coronavirus by donating cash or product or setting up a branded fundraising page for your customers and employees. Here are a few examples of how your gift can help:\n" +
                "\n" +
                "$75 can provide a Family Emergency Kit to feed and provide for a family of five in the U.S. for one week.\n" +
                "$450 can provide 6 families in the U.S. with Family Emergency Kits.\n" +
                "$1,000 can provide 250 international healthcare workers with a two-week supply of respirator masks\n" +
                "$5,000 can provide 200 international families with thermometers, hand sanitizer, tissues, antibacterial soap and educational materials\n" +
                "$6,000 can provide 100 children’s institutions in China with thermal scanning thermometers and disinfectants.\n" +
                "$50,000 can provide 12,500 healthcare workers with a two-week supply of respirator masks.\n" +
                "$100,000 can provide 4,000 families with thermometers, hand sanitizer, tissues, antibacterial soap, and educational materials.",
            donations: {
                website: true,
                direct: true,
            },
            donationData: {
                website: "https://www.worldvision.org/corporate/2020/03/18/donate-to-world-visions-coronavirus-emergency-response/",
                direct: "https://donate.worldvision.org/give/coronavirus-us",
            },
        }
    ];
};
export default causesAutofill;
